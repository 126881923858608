<template>
  <v-app-bar
    id="app-bar"
    absolute
    app
    color="transparent"
    flat
    height="75"
  >
    <v-btn
      class="mr-3"
      elevation="1"
      fab
      small
      @click="setDrawer(!drawer)"
    >
      <v-icon v-if="value">
        mdi-view-quilt
      </v-icon>

      <v-icon v-else>
        mdi-dots-vertical
      </v-icon>
    </v-btn>

    <v-toolbar-title
      class="hidden-sm-and-down font-weight-light"
      v-text="$route.name"
    />

    <v-spacer />
    <div class="body-1 font-weight-medium"><span class="red--text">{{computedValue}}</span> | <span class="red--text">{{name}}</span></div>
    <div class="mx-3" />

    <v-btn
      class="ml-2"
      min-width="0"
      text
      to="/"
    >
      <v-icon>mdi-view-dashboard</v-icon>
    </v-btn>

   <v-menu
      :close-on-content-click="true"
      :nudge-width="200"
      offset-y
      transition="slide-y-transition"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          class="ml-2"
          dark
          v-on="on"
        >
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>
        <v-card>
          <v-list>
          <v-list-item>
            <v-list-item-avatar>
              <img :src="photo" >
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ name }}</v-list-item-title>
              <v-list-item-subtitle>{{ email }} </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

          <v-card-actions right>
            <v-btn text color="normal" @click='logOut'><i>Logout</i></v-btn>
          </v-card-actions>
      </v-card>
      
    </v-menu>

  </v-app-bar>
</template>

<script>
  // Components
  import { VHover, VListItem } from 'vuetify/lib'

  // Utilities
  import { mapState, mapMutations } from 'vuex'
  import { _GLOBAL } from '@/api/server'
  import moment from 'moment'
  import { getSvrtime } from '@/api/absenonline'

  export default {
    name: 'DashboardCoreAppBar',

    components: {
      AppBarItem: {
        render (h) {
          return h(VHover, {
            scopedSlots: {
              default: ({ hover }) => {
                return h(VListItem, {
                  attrs: this.$attrs,
                  class: {
                    'black--text': !hover,
                    'white--text secondary elevation-12': hover,
                  },
                  props: {
                    activeClass: '',
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                }, this.$slots.default)
              },
            },
          })
        },
      },
    },

    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },

    data () {
      return {
        windowSize: {
          x: 0,
          y: 0
        },
        notifications: [ '' ],
        photo: '',
        userId: '',
        name: '',
        email: '',
        user: {},
        vtimer: null,
        timestamp: '',
        servertime: null

      }
    },
    computed: {
      ...mapState(['drawer']),
      computedValue: function(){
          return this.timestamp;
      }
    },
    destroyed () {
      clearInterval(this.vtimer)
    },
    created() {
      var vm = this
      getSvrtime().then(response => {
        // console.log('init server time', response.data)
        vm.servertime = response.data

        vm.vtimer = setInterval(function(){
          vm.servertime = moment(vm.servertime).add(1, 'seconds')
          vm.timestamp = moment(vm.servertime).format('YYYY-MM-DD HH:mm:ss')
        }, 1000)
      }).catch(err => {
        vm.timestamp = null
        console.log(err)
      })
    },
    mounted() {
      var vm = this
      vm.onResize()

      let usr = localStorage.getItem('user')
      let user = JSON.parse(usr)
      // console.log(user)

      if (user !== null) {
        vm.user = user
        vm.name = vm.user.fullname
        vm.email = vm.user.email
        vm.userId = vm.user.id
        vm.userNik = vm.user.nik

        if (vm.user.avatar !== null) {
          vm.photo = _GLOBAL.URLBACKEND + vm.user.avatar.url
        }
        else {
          vm.photo = _GLOBAL.URLBACKEND + '/images/iconman.png?imageView2/1/w/80/h/80'
          if (vm.user.gender === 'Wanita') {
            vm.photo = _GLOBAL.URLBACKEND + '/images/iconwoman.png?imageView2/1/w/80/h/80'
          }
        }
      }
    },

    methods: {
      ...mapMutations({
        setDrawer: 'SET_DRAWER',
      }),
      onResize () {
        this.windowSize = { x: window.innerWidth, y: window.innerHeight }
        // console.log(this.windowSize)
        if(this.windowSize.x <= 400){
          this.setDrawer(false)
        }
      },
      logOut () {
        // console.log('logout')
        this.$store.state.user = null
        localStorage.removeItem('user')
        localStorage.removeItem('jwt')

        this.$router.push('/login')
        // window.location.href = "/#/login"
      }
    },
  }
</script>
